import { Container, styled } from "@mui/material";

const MainContent = styled("main")(
  ({ theme, disablePadding, WhiteBackground }) => ({
    backgroundColor: WhiteBackground
      ? "rgb(255,255,255)"
      : theme.palette.background.default,
    minHeight: "calc(100vh - 422px)",
    display: "flex",
    flexDirection: "column",
    ...(disablePadding
      ? {} // No padding when disablePadding is true
      : {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(6),
          [theme.breakpoints.up("md")]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
          },
        }),
  })
);

const PageLayout = ({
  children,
  disablePadding = false,
  WhiteBackground = false,
}) => {
  return (
    <MainContent
      disablePadding={disablePadding}
      WhiteBackground={WhiteBackground}
    >
      <Container
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          ...(disablePadding && { padding: 0 }), // Remove padding if disablePadding is true
        }}
      >
        {children}
      </Container>
    </MainContent>
  );
};

export default PageLayout;
