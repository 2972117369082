import { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import logoSource from "@/assets/Logo-T.svg";
import { isMobile } from "@/utilities";

// Constants for layout measurements
const HEADER_HEIGHT = "70px";
const HEADER_PADDING = isMobile() ? "0px 16px" : "2em";

// Layout wrapper to handle fixed header offset
const NavigationLayout = styled("div")({
  position: "relative",
  minHeight: HEADER_HEIGHT,
  display: "flex",
  flexDirection: "column",
});

// Spacer to prevent content from going under the header
const HeaderSpacer = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  height: HEADER_HEIGHT,
  width: "100%",
});

const NavigationBar = styled("nav")(({ theme }) => ({
  width: "100%",
  position: "fixed",
  top: 0,
  zIndex: 1000,
  backgroundColor: theme.palette.background.default,
  height: HEADER_HEIGHT,
  boxShadow: theme.shadows[4],
}));

const Header = styled("header")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: HEADER_PADDING,
  height: "100%",
});

const Logo = styled("img")({
  width: 180,
});

const DesktopNav = styled("nav")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  alignItems: "center",
}));

const NavLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.dark,
  fontFamily: theme.typography.fontFamily,
  transition: "color 0.3s ease",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const MobileNavLink = styled(NavLink)(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
}));

const DesktopNavLink = styled(NavLink)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
}));

const MenuButton = styled("button")(() => ({
  width: 30,
  height: 20,
  position: "relative",
  cursor: "pointer",
  background: "none",
  border: "none",
  padding: 0,
}));

const MenuBar = styled("span")(({ theme, isOpen, position }) => ({
  display: "block",
  position: "absolute",
  height: "2px",
  width: "100%",
  background: theme.palette.primary.main,
  transition: "all 0.3s ease",
  ...(position === "top" && {
    top: isOpen ? "9px" : 0,
    transform: isOpen ? "rotate(45deg)" : "none",
  }),
  ...(position === "middle" && {
    top: "9px",
    opacity: isOpen ? 0 : 1,
  }),
  ...(position === "bottom" && {
    bottom: isOpen ? "9px" : 0,
    transform: isOpen ? "rotate(-45deg)" : "none",
  }),
}));

const MobileMenu = styled("aside")(({ theme, isOpen }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: `${theme.palette.background.default}`,
  display: isOpen ? "flex" : "none",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  zIndex: -1,
}));

const NavLinks = styled("ul")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem",
  listStyle: "none",
  padding: 0,
  margin: 0,
});

export const Navigation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpen, setIsOpen] = useState(false);

  return (
    <NavigationLayout>
      <NavigationBar>
        <Header>
          <Link to="/">
            <Logo src={logoSource} alt="Logo" />
          </Link>

          {isMobile ? (
            <MenuButton
              onClick={() => setIsOpen(!isOpen)}
              aria-expanded={isOpen}
              aria-label="Toggle navigation menu"
            >
              <MenuBar isOpen={isOpen} position="top" />
              <MenuBar isOpen={isOpen} position="middle" />
              <MenuBar isOpen={isOpen} position="bottom" />
            </MenuButton>
          ) : (
            <DesktopNav>
              <DesktopNavLink to="/">Home</DesktopNavLink>
              <DesktopNavLink to="/services">Services</DesktopNavLink>
              <DesktopNavLink to="/contact">Contact</DesktopNavLink>
            </DesktopNav>
          )}
        </Header>

        {isMobile && (
          <MobileMenu isOpen={isOpen}>
            <nav>
              <NavLinks>
                <li>
                  <MobileNavLink to="/" onClick={() => setIsOpen(false)}>
                    Home
                  </MobileNavLink>
                </li>
                <li>
                  <MobileNavLink
                    to="/services"
                    onClick={() => setIsOpen(false)}
                  >
                    Services
                  </MobileNavLink>
                </li>
                <li>
                  <MobileNavLink to="/contact" onClick={() => setIsOpen(false)}>
                    Contact
                  </MobileNavLink>
                </li>
              </NavLinks>
            </nav>
          </MobileMenu>
        )}
      </NavigationBar>
      <HeaderSpacer />
    </NavigationLayout>
  );
};

export default Navigation;
