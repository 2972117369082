import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "rgb(255, 255, 255)",
      light: "rgb(245, 245, 245)",
      dark: "rgb(211, 211, 211)",
    },
    secondary: {
      main: "rgb(180, 180, 180)",
      light: "rgb(200, 200, 200)",
      dark: "rgb(160, 160, 160)",
    },
    background: {
      default: "rgb(2, 2, 2)",
      paper: "rgb(30, 30, 30)",
      white: "rgb(255,255,255)",
    },
    text: {
      primary: "rgb(255, 255, 255)",
      secondary: "rgb(180, 180, 180)",
    },
    white: {
      color: "rgb(245, 245, 245)",
    },
  },
  typography: {
    fontFamily: '"Share Tech", "Roboto", sans-serif',

    h1: {
      fontSize: "calc(2rem + 0.5vw)",
      fontWeight: 500,
    },
    h2: {
      fontSize: "calc(1.5rem + 0.5vw)",
      fontWeight: 500,
    },
    body1: {
      fontSize: "calc(0.9rem + 0.2vw)",
      fontFamily: "Roboto",
    },
    body2: {
      fontSize: "calc(0.7rem + 0.2vw)",
      fontFamily: "Roboto",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: "none",
          padding: "10px 20px",
        },
        contained: {
          backgroundColor: "rgb(255, 255, 255)",
          color: "rgb(0, 0, 0)",
          "&:hover": {
            backgroundColor: "rgb(220, 220, 220)",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(0, 0, 0)",
        },
      },
    },
  },
});

export default theme;
