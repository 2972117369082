import { Box } from "@mui/material";
import { isMobile } from "@/utilities";

export const Hero = ({ videoSource, children }) => {
  const isMobileView = isMobile();
  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw",
        height: isMobileView ? "40vh" : "60vh",
        marginLeft: "calc(-50vw + 50%)",
        marginRight: "calc(-50vw + 50%)",
        overflow: "hidden",
      }}
    >
      <Box
        component="video"
        src={videoSource}
        autoPlay
        muted
        loop
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      {children && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};
