import { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Alert,
  Snackbar,
} from "@mui/material";
import emailjs from "@emailjs/browser";

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await emailjs.send(
        "service_9n67j33",
        "template_o1kb25x",
        formData,
        "NYTAIqaqH8mKt-vvh"
      );

      setSnackbar({
        open: true,
        message: "Message sent successfully!",
        severity: "success",
      });

      // Reset form
      setFormData({
        user_name: "",
        user_email: "",
        message: "",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to send message. Please try again.",
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container id="ok" maxWidth="md">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          bgcolor: "background.white",
          p: { xs: 3, md: 6 },
          borderRadius: 1,
          boxShadow: 2,
        }}
      >
        <Typography
          variant="h2"
          fontWeight={"bold"}
          gutterBottom
          color="primary"
          sx={{
            fontSize: { xs: "2rem", md: "2.5rem" },
            textAlign: "center",
            mb: 3,
          }}
        >
          Contact Us
        </Typography>

        <Typography
          variant="body1"
          gutterBottom
          color="text.secondary"
          textAlign="center"
          sx={{ mb: 4 }}
        >
          Please complete the form below or email us at{" "}
          <Box component="span" sx={{ color: "primary.main" }}>
            info@enexsub.com
          </Box>
        </Typography>

        <TextField
          name="user_name"
          label="Name"
          variant="outlined"
          value={formData.user_name}
          onChange={handleChange}
          required
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(255, 255, 255, 0.23)",
              },
              "&:hover fieldset": {
                borderColor: "primary.main",
              },
            },
          }}
        />

        <TextField
          name="user_email"
          label="Email"
          variant="outlined"
          type="email"
          value={formData.user_email}
          onChange={handleChange}
          required
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(255, 255, 255, 0.23)",
              },
              "&:hover fieldset": {
                borderColor: "primary.main",
              },
            },
          }}
        />

        <TextField
          name="message"
          label="Message"
          variant="outlined"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
          required
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(255, 255, 255, 0.23)",
              },
              "&:hover fieldset": {
                borderColor: "primary.main",
              },
            },
          }}
        />

        <Button
          type="submit"
          variant="contained"
          size="large"
          disabled={
            !formData.user_name || !formData.user_email || !formData.message
          }
          sx={{
            mt: 2,
            py: 1.5,
            fontSize: "1.1rem",
            width: { xs: "100%", sm: "200px" },
            alignSelf: "center",
          }}
        >
          Send Message
        </Button>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactForm;
