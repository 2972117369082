import { Typography, Button, Container, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Hero } from "@/components/Hero";
import VideoSource from "@/assets/010.mp4";

// Styled components with semantic names
const MainContent = styled("main")(() => ({
  width: "100%",
}));

const ServicesSection = styled("section")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
  },
}));

const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

const CallToAction = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(3),
  marginTop: theme.spacing(4),
}));

const ActionButton = styled(Button)(() => ({
  minWidth: 200,
}));

const Article = styled("article")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

const Home = () => {
  const navigate = useNavigate();

  return (
    <MainContent>
      <Hero videoSource={VideoSource}></Hero>
      <ServicesSection>
        <Container maxWidth="lg">
          <ContentWrapper>
            <Article>
              <Typography
                fontWeight={"bold"}
                variant="h2"
                color="primary"
                gutterBottom
              >
                We provide expert 3D modeling and 2D drafting services.
              </Typography>

              <Typography variant="body1" color="text.secondary" component="p">
                {`We're not your usual service provider - you're here because
                you're looking for something better, right? At ENEXSUB, we don't
                just deliver standard solutions. We provide precision-driven 3D
                modeling and 2D drafting services, customized to your needs.`}
              </Typography>

              <Typography variant="body1" color="text.secondary" component="p">
                {` What sets us apart? Our 3D models are designed to be easy to
                manufacture, thanks to our real-world experience. And our 2D
                drawings are clear, accurate, and ready for production.`}
              </Typography>
            </Article>

            <CallToAction>
              <Typography
                fontWeight={"bold"}
                variant="h2"
                color="primary"
                component="h2"
              >
                Check our services
              </Typography>
              <ActionButton
                variant="contained"
                size="large"
                onClick={() => navigate("/services")}
              >
                Go to services
              </ActionButton>
            </CallToAction>
          </ContentWrapper>
        </Container>
      </ServicesSection>
    </MainContent>
  );
};

export default Home;
