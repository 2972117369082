import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const ContentSection = styled("section")(() => ({
  maxWidth: 800,
  margin: "0 auto",
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

const StyledList = styled(List)(({ theme }) => ({
  "& .MuiListItem-root": {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export const Privacy = () => {
  return (
    <ContentSection>
      <Typography variant="h2" color="primary" gutterBottom component="h1">
        Privacy Policy
      </Typography>

      <Typography variant="body1" color="text.secondary" paragraph>
        This Privacy Policy describes how we collect, use, and share information
        about you when you visit our website. By using our website, you agree to
        the terms of this Privacy Policy.
      </Typography>

      <Section>
        <Typography variant="h4" color="primary" gutterBottom>
          Information We Collect
        </Typography>
        <StyledList>
          <ListItem>
            <ListItemIcon>
              <EmailOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Contact Information"
              secondary="We may collect your name and email address when you contact us through the contact form on our website."
              primaryTypographyProps={{ color: "text.primary" }}
              secondaryTypographyProps={{ color: "text.secondary" }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Purpose"
              secondary="We collect this information solely for the purpose of contacting you to respond to your inquiry or request for information."
              primaryTypographyProps={{ color: "text.primary" }}
              secondaryTypographyProps={{ color: "text.secondary" }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Third-Party Sharing"
              secondary="We will not share your name or email address with any third parties without your consent."
              primaryTypographyProps={{ color: "text.primary" }}
              secondaryTypographyProps={{ color: "text.secondary" }}
            />
          </ListItem>
        </StyledList>
      </Section>

      <Section>
        <Typography variant="h4" color="primary" gutterBottom>
          Security Measures
        </Typography>
        <StyledList>
          <ListItem>
            <ListItemIcon>
              <SecurityOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Data Protection"
              secondary="We take reasonable steps to protect your personal information from unauthorized access, disclosure, or alteration."
              primaryTypographyProps={{ color: "text.primary" }}
              secondaryTypographyProps={{ color: "text.secondary" }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SecurityOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Transmission Risk"
              secondary="However, we cannot guarantee the security of your personal information transmitted to our website, and any transmission is at your own risk."
              primaryTypographyProps={{ color: "text.primary" }}
              secondaryTypographyProps={{ color: "text.secondary" }}
            />
          </ListItem>
        </StyledList>
      </Section>

      <Section>
        <Typography variant="h4" color="primary" gutterBottom>
          Policy Updates
        </Typography>
        <StyledList>
          <ListItem>
            <ListItemIcon>
              <UpdateOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Changes"
              secondary="We may update this Privacy Policy from time to time. If we make material changes, we will notify you by email or by posting a notice on our website prior to the change becoming effective."
              primaryTypographyProps={{ color: "text.primary" }}
              secondaryTypographyProps={{ color: "text.secondary" }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <UpdateOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Last Updated"
              secondary="Revised on April 18, 2023"
              primaryTypographyProps={{ color: "text.primary" }}
              secondaryTypographyProps={{ color: "text.secondary" }}
            />
          </ListItem>
        </StyledList>
      </Section>
    </ContentSection>
  );
};

export default Privacy;
