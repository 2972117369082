import { Route, Routes, useLocation } from "react-router-dom";
import { Navigation } from "@/components/Navigation";
import Footer from "@/components/Footer";
import Home from "@/pages/Home";
import { Contact } from "@/pages/Contact";
import { Privacy } from "@/pages/Privacy";
import Services from "@/pages/Services";
import PageLayout from "@/components/PageLayout";
import useScrollToTop from "@/hooks/useScrollToTop";
import { Box } from "@mui/material";

function App() {
  const isHome = useLocation().pathname === "/";
  const isContact = useLocation().pathname === "/contact";
  useScrollToTop();
  return (
    <Box minHeight={"100vh"}>
      <Navigation />
      <PageLayout disablePadding={isHome} WhiteBackground={isContact}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/services" element={<Services />} />
        </Routes>
      </PageLayout>
      <Footer />
    </Box>
  );
}

export default App;
