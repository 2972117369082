import {
  Box,
  Container,
  Grid,
  Typography,
  Link as MuiLink,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import { styled } from "@mui/material/styles";

const navigation = [
  { name: "Home", href: "/" },
  { name: "Services", href: "/services" },
  { name: "Contact", href: "/contact" },
  { name: "Privacy", href: "/privacy" },
];

const FooterSection = styled("footer")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  marginTop: "auto",
}));

const FooterLogo = styled("img")({
  height: 40,
  marginBottom: 16,
  filter: "brightness(0.8)",
});

const QuickLinks = styled("nav")(({ theme, isMobile }) => ({
  display: "flex",
  flexDirection: isMobile ? "row" : "column",
  flexWrap: "wrap",
  gap: theme.spacing(2),
}));

const ContactLink = styled(MuiLink)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const CopyrightSection = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingTop: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  textAlign: "center",
}));

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <FooterSection>
      <Container maxWidth="lg">
        <Grid container spacing={4} component="div" role="presentation">
          {/* Company Information */}
          <Grid item xs={12} md={4} component="div" role="presentation">
            <FooterLogo src="/Small logo.png" alt="ENEXSUB" />
            <Typography variant="body2" component="p">
              Expert 3D modeling and 2D drafting services for your engineering
              needs.
            </Typography>
          </Grid>

          {/* Navigation */}
          <Grid item xs={12} md={4} component="div" role="navigation">
            <Typography
              fontWeight={"bold"}
              variant="body1"
              color="primary"
              gutterBottom
            >
              Quick Links
            </Typography>
            <QuickLinks isMobile={isMobile} aria-label="Footer navigation">
              {navigation.map((item) => (
                <MuiLink
                  key={item.name}
                  component={Link}
                  to={item.href}
                  sx={(theme) => ({
                    "&:hover": {
                      color: "secondary.main",
                    },
                    ...theme.typography.body2,
                  })}
                >
                  {item.name}
                </MuiLink>
              ))}
            </QuickLinks>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={4} component="div" role="contentinfo">
            <Typography variant="body1" color="primary" gutterBottom>
              Connect With Us
            </Typography>
            <address style={{ fontStyle: "normal" }}>
              <Box sx={{ mb: 2 }}>
                <ContactLink
                  href="mailto:info@enexsub.com"
                  aria-label="Email us"
                  sx={(theme) => ({
                    "&:hover": {
                      color: "secondary.main",
                    },
                    ...theme.typography.body2,
                  })}
                >
                  <EmailIcon aria-hidden="true" />
                  info@enexsub.com
                </ContactLink>
              </Box>
              <Box sx={{ mb: 2 }}>
                <ContactLink
                  href="https://www.linkedin.com/company/enexsub/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit our LinkedIn page"
                  sx={(theme) => ({
                    "&:hover": {
                      color: "secondary.main",
                    },
                    ...theme.typography.body2,
                  })}
                >
                  <LinkedInIcon aria-hidden="true" />
                  LinkedIn
                </ContactLink>
              </Box>
            </address>
          </Grid>
        </Grid>

        {/* Copyright */}
        <CopyrightSection>
          <Typography variant="body2" component="p">
            © {new Date().getFullYear()} ENEXSUB. All rights reserved.
          </Typography>
        </CopyrightSection>
      </Container>
    </FooterSection>
  );
};

export default Footer;
