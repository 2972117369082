import { styled } from "@mui/material/styles";
import {
  Typography,
  Container,
  Grid,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import servicesImg from "@/assets/img5.png";

// Constants
const servicesList = [
  "3D Modeling",
  "General Arrangement Drawings",
  "Assembly Drawings",
  "Detailed Drawings",
  "Part Drawings",
  "Machining Drawings",
  "Lifting Arrangement Drawings",
  "Research and Development (R&D) Assistance",
  "Concept Design",
  "Cutting File Creation (DXF)",
  "Material Take-Off (MTO)",
];

const ServicesImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "300px",
  objectFit: "cover",
  borderRadius: theme.shape.borderRadius * 2,
  [theme.breakpoints.up("md")]: {
    height: "100%",
  },
}));

const ServiceContent = styled("section")(() => ({
  maxWidth: 600,
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

const ServicesList = styled(List)(({ theme }) => ({
  "& .MuiListItem-root": {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

const ActionContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

const ContactButton = styled(Button)(() => ({
  minWidth: 200,
}));

const ServiceDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const Services = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <ServicesImage
            src={servicesImg}
            alt="3D modeling and drafting services illustration"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ServiceContent>
            <Typography
              variant="h2"
              fontWeight={"bold"}
              color="primary"
              gutterBottom
              component="h1"
              mb={4}
            >
              Our Services
            </Typography>

            <ServiceDescription
              variant="body1"
              color="text.secondary"
              component="p"
            >
              {`At ENEXSUB, we offer a comprehensive range of 3D modeling and 2D
              drafting services tailored to meet your project's needs.`}
            </ServiceDescription>

            <ServicesList component="ul" aria-label="Services offered">
              {servicesList.map((service) => (
                <ListItem key={service} disableGutters>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={service}
                    primaryTypographyProps={{
                      color: "text.secondary",
                      component: "span",
                    }}
                  />
                </ListItem>
              ))}
            </ServicesList>

            <ActionContainer>
              <ContactButton
                variant="contained"
                size="large"
                onClick={() => navigate("/contact")}
              >
                Contact Us
              </ContactButton>
            </ActionContainer>
          </ServiceContent>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Services;
